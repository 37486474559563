<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar :isList="true" :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick">

            <div class="top-btns-bar-right-section">
                <v-icon
                    v-if="collection != 'ChancelleryResolution'"
                    class="extend-filter"
                    v-tooltip.left-center="$t('Расширенный_поиск')"
                    left
                    @click="openExtendedFilterDialog"
                >
                    mdi-filter
                </v-icon>

                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        >

            <ExtendedFilterPanel 
                v-if="$helpers.isExtendedFilterPanelVisible(extendedTableFilter)"
                v-bind:value.sync="extendedTableFilter"
            />

        </FilterPanel>

        <component
            :is="collection"
            :status="status"
            :search="search"
            @change_loaddata_status="onLoadDataStatusChanged"
            ref="currentComponent"
        />

        <IncommingExFilter 
            v-if="collection=='Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument' || collection=='Avrora.Objects.Modules.Docflow.DocflowObjects.ProtocolDocument'"
            v-model="extendedTableFilter" 
            ref="incommingExFilterDlg" 
        />
        <OutgoingExFilter 
            v-if="collection=='Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument'"
            v-model="extendedTableFilter" 
            ref="outgoingExFilterDlg" 
        />
        <NPAExFilter 
            v-if="collection=='Avrora.Objects.Modules.Docflow.DocflowObjects.NPADocument'"
            v-model="extendedTableFilter" 
            ref="npaExFilterDlg" 
        />
        <InnerExFilter 
            v-if="collection=='Avrora.Objects.Modules.Docflow.DocflowObjects.InnerDocument'"
            v-model="extendedTableFilter" 
            ref="innerExFilterDlg" 
        />
        <StatementsExFilter 
            v-if="collection=='CitizenStatement'"
            v-model="extendedTableFilter" 
            ref="statementsExFilterDlg" 
        />

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';
import NPADocumentList from './lists/NPADocumentList.vue';
import ProtocolDocumentList from './lists/ProtocolDocumentList.vue';
import IncomingDocumentList from './lists/IncomingDocumentList.vue';
import OutgoingDocumentList from './lists/OutgoingDocumentList.vue';
import InnerDocumentList from './lists/InnerDocumentList.vue';
import CitizenStatementList from './lists/CitizenStatementList.vue';
import ResolutionsList from './lists/ResolutionsList.vue';
import ExtendedFilterPanel from '@/components/ExtendedFilterPanel.vue';
import IncommingExFilter from '@/components/dialogs/extended-filters/chancellary/incomming'
import OutgoingExFilter from '@/components/dialogs/extended-filters/chancellary/outgoing'
import NPAExFilter from '@/components/dialogs/extended-filters/chancellary/npa'
import InnerExFilter from '@/components/dialogs/extended-filters/chancellary/inner'
import StatementsExFilter from '@/components/dialogs/extended-filters/chancellary/statements'

export default {
    name: "ChancellaryProxy",
    components: {
        Toolbar,
        FilterPanel,
        ExtendedFilterPanel,
        IncommingExFilter,
        OutgoingExFilter,
        NPAExFilter,
        InnerExFilter,
        StatementsExFilter,        
        "Avrora.Objects.Modules.Docflow.DocflowObjects.NPADocument": NPADocumentList,
        "Avrora.Objects.Modules.Docflow.DocflowObjects.ProtocolDocument": ProtocolDocumentList,
        "Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument": IncomingDocumentList,
        "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument": OutgoingDocumentList,
        "Avrora.Objects.Modules.Docflow.DocflowObjects.InnerDocument": InnerDocumentList,
        "CitizenStatement": CitizenStatementList,
        "ChancelleryResolution": ResolutionsList,
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            filterDataSource: null,
            dataloading:false,
            filterloading:false,
            collectionloading:false,
        }
    },
    computed: {
        filterloadingcomp:{
            get: function() { return this.filterloading; },
            set: function(newValue) { this.filtreloading = newValue; this.refreshOverlay(); }
        },
        collectionloadingcomp:{
            get: function() { return this.collectionloading; },
            set: function(newValue) { this.collectionloading = newValue; this.refreshOverlay(); }
        },
        collection: {
            get: function() {
                return this.$store.getters['chancellary/getCollection'];
            },
            set: function(newValue) {
                this.$store.commit('chancellary/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                var statuses = this.$store.getters['chancellary/getStatus'];
                if (!statuses) return "";
                
                var result =  statuses.find(x => x.collection == this.collection)?.value ?? "";
                return result;
            },
            set: function(newValue) {                    
                if (newValue) {
                    this.$store.commit('chancellary/PUSH_STATUS', { collection: this.collection, value: newValue });

                    if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                        this.$refs.currentComponent.resetPage();
                    }
                }
            }
        },
        search: {
            get: function() {
                var searches = this.$store.getters['chancellary/getSearches'];

                if (!searches) return "";
                
                return searches.find(x => x.collection == this.collection)?.value ?? "";
            },
            set: _.debounce(function(v) {
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }
                this.$store.commit('chancellary/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
        canSignChief () {
            var user = this.$store.getters['auth/getUserInfo'];
            return user?.permissions.includes("CanSignChief") ?? false;
        },
        extendedTableFilter: {
            get () {
                var extendedFilters = this.$store.getters['chancellary/getExtendedFilters'];

                if (!extendedFilters) 
                    return {};

                var result =  extendedFilters.find(x => x.collection == this.collection)?.value ?? {};
                return result;
            },
            set (newValue) {    
                if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                    this.$refs.currentComponent.resetPage();
                }

                if (newValue)
                    this.$store.commit('chancellary/PUSH_EXTENDED_FILTERS', { collection: this.collection, value: newValue } );
            }
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        onLoadDataStatusChanged(status){
            this.dataloading = status;
            this.refreshOverlay();    
        },
        refreshOverlay(){
            this.setOverlayVisible({ visible: this.dataloading || this.filterloading || this.collectionloading });
        },
        onToolbarClick(event, btn) {
            this[btn.Action]();
        },
        async loadCollectionData() {
            this.collectionloadingcomp = true;
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/actions/collection?type=Chancellery.Document`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });
            
            if (response) {
                await this.loadFilters();
                this.dataSource = response.data.payload;
            }
            this.collectionloadingcomp = false;
        },
        async loadFilters() {
            this.filterloadingcomp = true;
            let filterResponse = await httpAPI({
                url: `/api/chancellary/filter?collection=${this.collection}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (filterResponse) {
                var permissions = (this.$store.getters['auth/getUserInfo'])?.permissions ?? [];
                filterResponse.data.payload.filter.items = filterResponse.data.payload.filter.items.filter(i => this.$_.difference(i.requires, permissions).length === 0);
                this.filterDataSource = filterResponse.data.payload;
            }
            this.filterloadingcomp = false;
        },
        async openExtendedFilterDialog () {
            try
            {

                switch(this.collection)
                {
                    case "Avrora.Objects.Modules.Docflow.DocflowObjects.ProtocolDocument": await this.$refs.incommingExFilterDlg.open(); break;
                    case "Avrora.Objects.Modules.Docflow.DocflowObjects.IncomingDocument": await this.$refs.incommingExFilterDlg.open(); break;
                    case "Avrora.Objects.Modules.Docflow.DocflowObjects.OutgoingDocument": await this.$refs.outgoingExFilterDlg.open(); break;
                    case "Avrora.Objects.Modules.Docflow.DocflowObjects.InnerDocument": await this.$refs.innerExFilterDlg.open(); break;
                    case "Avrora.Objects.Modules.Docflow.DocflowObjects.NPADocument": await this.$refs.npaExFilterDlg.open(); break;
                    case "CitizenStatement": await this.$refs.statementsExFilterDlg.open(); break;
                    case "ChancelleryResolution": break;
                    default: break;
                }
            }
            catch (ex)
            {
                if (ex.isCancelled) 
                    console.warn(ex.message);
                else
                    console.error(ex.message);
            }                
        },
        async Refresh() {
            await this.loadFilters(false);
            this.$refs.currentComponent.getData(true);
        }
    },
    watch: {
        collection : {
            handler () {
                this.loadCollectionData();
            }
        }
    },
    beforeCreate() {
        this.$store.dispatch('chancellary/initCollection');
    },
    async created () {
        try
        {
            await this.loadCollectionData();
        }
        catch
        {
            this.setOverlayVisible({ visible: false });
        }          
    }
}
</script>