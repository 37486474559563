<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :item-class="itemRowBackground"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document' })">
                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.createdate`]="{ item }">
                    <div>{{dateFormat(item.createdate, 'DD.MM.YYYY HH:mm:ss')}}</div>
                </template>

                <template v-slot:[`item.outdate`]="{ item }">
                    <div>{{dateFormat(item.outdate, 'DD.MM.YYYY')}}</div>
                </template>

                <template v-slot:[`item.correspondent`]="{ item }">
                    <div class="etw-text-container">
                        <div class="etw-text" v-tooltip.top-center="item.correspondent">
                            {{item.correspondent}}
                        </div>
                    </div>
                </template>
                
                <template v-slot:[`item.theme`]="{ item }">
                    <div class="elipsis-text-wrap">                        
                        <v-doc-type-chip :type="item.doctypeof" :text="item.doctype"/>
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.theme">
                                {{item.theme}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system"

export default {
    columnIndex:  {
        'createdate': 7,
        'outnumber': 2,
        'correspondent': 5,
        'theme': 6,
        'outdate': 3,
    },
    props: {
        status: {
            type: String,
            default: null
        },
        search: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("Дата_поступления"), value: 'createdate', width: '150px' },
                { text: i18n.t("Исх_№"), value: 'outnumber', width: '150px' },
                { text: i18n.t("Дата_исходящего"), value: 'outdate', width: '150px' },
                { text: i18n.t("Корреспондент"), value: 'correspondent', width: '20%' },
                { text: i18n.t("Краткое_содержание"), value: 'theme'}
            ],
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        options: {
            get: function() {
                return this.$store.getters['chancellary/incoming/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('chancellary/incoming/SET_TABLE_OPTION', newValue);
            }
        },
        extendedTableFilter: {
            get: function() {
                var extendedFilters = this.$store.getters['chancellary/getExtendedFilters'];
                if (!extendedFilters) return {};
                
                var result =  extendedFilters.find(x => x.collection == this.collection)?.value ?? {};
                return result;
            }
        },
        collection() {
            return this.$store.getters['chancellary/getCollection'];
        },
        filter() {
            return {
                ...this.options,
                SearchStr: this.search,
                RegState: this.status,
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedTableFilter)
            }
        },
    },
    methods: {
        itemRowBackground (item) {
            return item.readed ? '' : 'font-weight-bold';
        },
        resetPage() {
            this.$store.commit('chancellary/incoming/SET_TABLE_OPTION_PAGE', 1);
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            this.$emit("change_loaddata_status",this.loading);
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();
            
            let response = await httpAPI({
                url: `/api/chancellary/list?filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;            
            this.loading = false;
            this.$emit("change_loaddata_status",this.loading);
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, RegState, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? true : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "createdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 7;
            return { SearchStr, RegState, DocType: (this.$store.getters['chancellary/getCollection']), OrderBy, OrderDir, Limit: itemsPerPage, Offset: (page - 1) * itemsPerPage, ...extendedFilterData };
        },
        dateFormat: sys.dateFormat,
    },
    watch: {
        filter: {
            handler() {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>
